import { Modal, Toast } from 'bootstrap';
import Plyr from 'plyr';
import $ from 'jquery';
$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    var cartModal, toast, playerModal, videoPlayer;
    if ($("#cartModal").length) {
        cartModal = new Modal($("#cartModal"), {
            backdrop: "static",
            keyboard: true,
        });
    }
    if ($("#toast").length) {
        toast = new Toast($("#toast"));
    }

    if ($("#player").length) {
        videoPlayer = new Plyr('#player', playerConfig);
    }



    if ($("#playerModal").length) {
        playerModal = new Modal($("#playerModal"), {
            backdrop: "static",
            keyboard: true,
        });

        document.getElementById("playerModal").addEventListener("hidden.bs.modal", function (event) {
            videoPlayer.stop();
        });
    }

    $(document).on("click", '.playvideo', function (e) {
        e.preventDefault();
        let url = $(this).data("href");
        $.ajax({
            url: url,
            type: "GET",
            dataType: "json",
        }).done(function (resp) {
            if (resp.status == true) {
                videoPlayer.source = resp.data;
                $("#playerModalLabel").html('<i class="fa fa-play-circle"></i> ' + resp.data.title);
                videoPlayer.play();
                playerModal.show();
            } else {
                showToast("Watch urls not found!");
            }
        }).fail(function () {
            showToast("Failed to Play!");
        });

    });


    function showToast(message, type = true) {
        if ($("#toast").length && $('#toast-body').length) {
            let style = (type == true) ? "success fa fa-ok-circled" : "danger fa fa-attention";
            $('#toast-body').html('<span class="text-' + style + '"> ' + message + '</span>');
            toast.show();
        }
    }

    $(document).on("click", '.requestDownload', function (e) {
        e.preventDefault();
        let Id = $(this).data("id");
        $.ajax({
            url: app.routes.requestdown,
            dataType: "json",
            type: "post",
            data: {
                id: Id
            }
        }).done(function (resp) {
            showToast(resp.message);
        }).fail(function () {
            showToast("Failed to request download!", false);
        });
    });



    $(document).on("click", '.downloadPicture', function (e) {
        e.preventDefault();
        let downloadModal = Modal.getOrCreateInstance($("#downloadModal"), {
            backdrop: "static",
            keyboard: true,
        });
        let Id = $(this).data("id");
        $.ajax({
            url: app.routes.downloadurls,
            dataType: "json",
            type: "GET",
            data: {
                id: Id
            }
        }).done(function (resp) {
            if (resp.status == true) {
                let dataBody = "";
                $.each(resp.data, function (k, v) {
                    dataBody += '<a href="' + v.url + '" class="btn btn-outline-dark btn-lg btn-block btn-sm"><i class="fa fa-download"></i> ' + v.title + '</a>';
                });
                $("#download-body").html(dataBody);
                downloadModal.show();
            } else {
                showToast(resp.message, false);
            }
        }).fail(function () {
            showToast("Error to download!", false);
        });
    });

    $(document).on("click", '.toggleFav', function (e) {
        e.preventDefault();
        let fav = $(this);
        let url = fav.data("href");
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
        }).done(function (res) {
            console.log(res.message);
            if (res.status == true) {
                if (fav.hasClass("on")) {
                    fav.removeClass('on');
                    fav.html('<i class="fa fa-heart" aria-hidden="true"></i></span> ' + app.lang.removeFav);
                } else {
                    fav.addClass("on");
                    fav.html('<span class="text-danger"><i class="fa fa-heart" aria-hidden="true"></i></span> ' + app.lang.addFav);
                }
                showToast(res.message);
            } else {
                showToast(res.message, false);
            }
        }).fail(function () {
            showToast("Failed Request", false);
        });
    });



    $(document).on("click", '.addCartWithOption', function (e) {
        e.preventDefault();
        $(this).attr("disabled", "disabled");
        let cartId = $(this).data("id");
        let cartName = $(this).data("label");
        $("#cartId").val(cartId);
        $("#cartLabel").val(cartName);
        $("#cartModalLabel").text("#" + cartId + " - " + cartName);
        if (!($("#cartModalAlert").hasClass("d-none"))) {
            $("#cartModalAlert").addClass("d-none");
        }
        cartModal.show();
    });

    $(document).on("click", ".addCart", function (e) {
        e.preventDefault();
        $(this).attr("disabled", "disabled");
        let id = $(this).data("id");
        let label = $(this).data('label');
        let totalSl = $("#totalSelection");
        let total = parseInt(totalSl.text());
        addCart(id, label, "", "", "", function (res) {
            console.log(res);
            if (res.status == true) {
                total += 1;
                totalSl.text(total);
                $("#reviewButton").removeAttr("disabled");
                showCart(false, id, label);
            }
        });
    });

    $("#cartForm").on("submit", function (e) {
        e.preventDefault();
        let totalSl = $("#totalSelection");
        let total = parseInt(totalSl.text());
        var cartForm = $(this).serializeArray()
        let id = cartForm[1].value;
        let label = cartForm[2].value;
        let size = cartForm[3].value;
        let quantity = cartForm[4].value;
        let suggestion = cartForm[5].value;
        addCart(id, label, size, quantity, suggestion, function (res) {
            console.log(res.message);
            if (res.status == true) {
                if (!($("#cartModalAlert").hasClass("d-none"))) {
                    $("#cartModalAlert").addClass("d-none");
                }
                total += 1;
                totalSl.text(total);
                $("#reviewButton").removeAttr("disabled");
                showCart(false, id, label);
                $("#cartSuggestion").val("");
                cartModal.hide();
            } else {
                $("#cartModalAlert").html(res.message);
                $("#cartModalAlert").removeClass("d-none");
            }
        })
    });

    $("#reviewButton").on("click", function (e) {
        let cartUrl = $(this).data("href");
        let reviewModal = Modal.getOrCreateInstance($("#reviewModal"), {
            backdrop: "static",
            keyboard: true,
        });
        e.preventDefault();
        $.ajax({
            url: cartUrl,
            dataType: "json",
            type: "GET",
        }).done(function (res) {
            console.log(res.message);
            if (res.status == true) {
                let data = res.data;
                var cartRows = '';
                $.each(data.suggestion, function (k, v) {
                    cartRows += '<tr><td>' + v.id + '</td><td><img class="spotlight img-small" src="' + v.img.source + '" data-src="' + v.img.url + '" alt="' + v.img.alt + '"></td><td>' + v.quantity + '</td><td>' + v.size + '</td><td><td> <td>' + v.message + '</td></tr>';
                });
                $("#cartDetails").html('<tr><td id="#cartTotal" class="fw-bold">' + data.total_item + '</td>  <td class="fw-bold">' + data.total_quantity + '</td><td id="#totalSuggest" class="fw-bold">' + data.total_suggestion + '</td></tr>');
                $("#cartBody").html(cartRows);
                reviewModal.show();
            }
        }).fail(function () {
            console.log("Failed to Review Picture");
        })

    });

    $(document).on("click", ".removeCart", function (e) {
        e.preventDefault();
        $(this).attr("disabled", "disabled");
        let totalSl = $("#totalSelection");
        let total = parseInt(totalSl.text());
        let Id = $(this).data("id");
        let label = $(this).data('label');
        $.ajax({
            url: app.routes.removecart,
            type: "DELETE",
            dataType: "json",
            data: {
                id: Id,
                albumId: app.vars.albumId
            }
        }).done(function (res) {
            console.log(res.message);
            if (res.status == true) {
                total -= 1;
                if (total <= 0) {
                    $("#reviewButton").attr("disabled", 'disabled');
                }
                totalSl.text(total);
                showCart(true, Id, label);
            }
        }).fail(function () {
            console.log("remove cart failed");
        });

    });


    function showCart(status, id, label) {
        let className = (status == true) ? "between" : "center";
        let cart = '<div class="d-flex flex-wrap justify-content-' + className + '">';
        if (status === true) {
            cart += '<button type="button" role="button" data-id="' + id + '" data-label="' + label + '" class="btn btn-outline-secondary btn-sm addCartWithOption">' + app.lang.addWithOption + '</button>';
            cart += '<button type="button" role="button" data-id="' + id + '" data-label="' + label + '" class="btn btn-outline-danger btn-sm addCart">' + app.lang.addSelection + '</button>';
        } else {
            cart += '<button type="button" role="button" data-id="' + id + '" data-label="' + label + '" class="btn btn-danger btn-sm removeCart">' + app.lang.removeSelection + '</button>';
        }
        cart += '</div>';
        $('#cart-' + id).html(cart);
    }

    function addCart(id, name, size, quantity, suggestion, callback) {
        $.ajax({
            url: app.routes.addcart,
            type: "POST",
            dataType: "json",
            data: {
                id: id,
                albumId: app.vars.albumId,
                name: name,
                size: size,
                quantity: quantity,
                suggestion: suggestion,
            }
        }).done(function (resp) {
            return callback(resp);
        }).fail(function () {
            console.log("Failed to add cart!")
        });
    }


    function displayAlbums(albums) {
        let tpl;
        $.each(albums, function (i, a) {
            tpl = '<div class="col" style="display:none">' +
                '<div class="card thumbnail">' +
                '<a href="' + a.url + '">' +
                '<div class="cover">' +
                '<img src="' + a.image.src + '" alt="' + a.image.alt + '" class="card-img-top">' +
                '<div class="bottom-right">' + a.pictures_count + ' ' + app.lang.pictures + '</div>' +
                '</div></a>';
            tpl += '<div class="card-body">' +
                '<small class="text-muted">#' + a.id + '</small>' +
                '<p class="title">' + a.name + '</p>' +
                '<div class="d-flex align-items-center justify-content-between rounded-pill bg-light px-3 py-2 mt-2">' +
                '<p class="small mb-0"><i class="fa fa-calendar" aria-hidden="true"></i></i><span class="fw-bold"> ' + a.modified_at + '</span></p>';
            '</div></div></div></div>';
            $("#pagination").prev().append(tpl);
        });

        $(".col:hidden").fadeIn();
    }


    function displayPictures(pictures) {
        let tpl;
        $.each(pictures, function (i, p) {
            tpl = '<div class="col" style="display:none">' +
                '<div class="card thumbnail">' +
                '<div class="cover">' +
                '<img src="' + p.image.src + '" data-src="' + p.image.url + '" class="spotlight card-img-top" alt="' + p.image.alt + '">' +
                '<div class="top-left"><small>#' + p.id + '</small></div>';

            if (p.downloadstatus != null) {
                tpl += '<div class="right">' + p.downloadstatus + '</div>';
            }

            tpl += '<div class="bottom-right text-truncate">' + p.name + '</div>' +
                '</div>' +
                '<div class="card-body">';
            if (app.vars.selection == 1) {
                if (!(app.vars.hideAfterSelection == 1 && p.last_ordered_at != null)) {
                    tpl += '<div id="cart-' + p.id + '">';
                    if (p.cart == false) {
                        tpl += '<div class="d-flex flex-wrap justify-content-between">' +
                            '<button type="button" role="button" data-id="' + p.id + '" data-label="' + p.name + '" class="btn btn-outline-secondary btn-sm addCartWithOption">' + app.lang.addWithOption + '</button>' +
                            '<button type="button" role="button" data-id="' + p.id + '" data-label="' + p.name + '" class="btn btn-outline-danger btn-sm addCart">' + app.lang.addSelection + '</button>' +
                            '</div>';
                    }
                    else {
                        tpl += '<div class="d-flex justify-content-center">' +
                            '<button type="button" role="button" data-id="' + p.id + '" data-label="' + p.name + '" class="btn btn-danger btn-sm removeCart">' + app.lang.removeSelection + '</button>' +
                            '</div>';
                    }
                    tpl += '</div>';
                }
            }
            if (p.last_ordered_at != null) {
                tpl += '<p class="m-1 text-center"><span class="text-success">' + app.lang.submited + ': </span>' +
                    '<strong>' + p.last_ordered_at + '</strong>' +
                    '</p>';
            }
            tpl += '<div class="d-flex align-items-center justify-content-between rounded-pill bg-light px-3 py-2 mt-2">' +
                '<p class="small mb-0"><i class="fa fa-picture-o mr-2"></i><span class="fw-bold"><i class="fa fa-calendar" aria-hidden="true"></i> ' + p.modified_at + '</span></p>';

            tpl += '<div class="dropup">' +
                '<a href="#" id="menu" data-bs-toggle="dropdown" aria-expanded="false">' +
                '<i class="fa fa-dot-3" aria-hidden="true"></i>' +
                '<span class="visually-hidden">Menu</span>' +
                '</a>' +
                '<ul class="dropdown-menu" aria-labelledby="menu">';

            if (p.fav.is == false) {
                tpl += '<li><a class="dropdown-item toggleFav on" href="#" data-href="' + p.fav.url + '"><span class="text-danger"><i class="fa fa-heart" aria-hidden="true"></i></span> ' + app.lang.addFav + '</a></li>';
            }
            else {
                tpl += '<li><a class="dropdown-item toggleFav on" href="#" data-href="' + p.fav.url + '"><i class="fa fa-heart" aria-hidden="true"></i> ' + app.lang.removeFav + '</a></li>';
            }
            if (p.download_id != null || p.is_download == true) {
                tpl += '<li><a class="dropdown-item downloadPicture" href="#" data-id="' + p.id + '"><i class="fa fa-download" aria-hidden="true"></i> ' + app.lang.download + '</a></li>';
            } else if (app.vars.hideDownRequest != true) {
                tpl += '<li><a class="dropdown-item requestDownload" href="#" data-id="' + p.id + '"><i class="fa fa-mail"  aria-hidden="true"></i> ' + app.lang.requestDownload + '</a></li>';
            }

            tpl += '</ul></div></div></div></div></div>';
            $("#pagination").prev().append(tpl);
        });

        $(".col:hidden").fadeIn();
    }

    function displayImages(images) {
        let tpl;
        $.each(images, function (k, i) {
            tpl = '<div class="col" style="display:none">' +
                '<div class="thumbnail">' +
                '<div class="cover">' +
                '<img src="' + i.image.src + '" data-src="' + i.image.url + '" class="spotlight rounded w-100" alt="' + i.image.alt + '">';
            if (i.name) {
                tpl += '<div class="bottom-right text-truncate">' + i.name + '</div>';
            }
            tpl += '</div></div></div>';
            $("#pagination").prev().append(tpl);
        });
        $(".col:hidden").fadeIn();
    }


    function displayVideos(videos) {
        let tpl;
        $.each(videos, function (i, v) {
            tpl = '<div class="col" style="display:none">' +
                '<div class="card thumbnail">' +
                '<div class="cover">' +
                '<a href="#" data-href="' + v.url + '" class="playvideo"><img src="' + v.image.src + '"alt="' + v.image.alt + '" class="card-img-top"></a>' +
                '<div class="top-left"><small>#' + v.id + '</small></div>' +
                '</div>' +
                '<div class="card-body">' +
                '<p class="title">' + v.name + '</p>' +
                '<div class="d-flex align-items-center justify-content-between rounded-pill bg-light px-3 py-2 mt-2">' +
                '<p class="small mb-0"><i class="fa fa-calendar" aria-hidden="true"></i> ' + v.modified_at + '</p>' +
                '<div class="dropup"><a href="#" class="text-decoration-none" id="menu" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-download" aria-hidden="true"></i> ' + app.lang.download + '</a><ul class="dropdown-menu" aria-labelledby="menu">';
            $.each(v.down_urls, function (i, d) {
                tpl += '<li><a class="dropdown-item" href="' + d.url + '"><i class="fa fa-download" aria-hidden="true"></i> ' + d.title + '</a></li>';
            });
            tpl += '</ul></div></div></div></div></div>';
            $("#pagination").prev().append(tpl);
        });
        $(".col:hidden").fadeIn();
    }

    function LoadData() {
        ApiPaging(function (resp) {
            if (resp.data) {
                switch (resp.meta.type) {
                    case "pictures":
                        displayPictures(resp.data);
                        break;

                    case "albums":
                        displayAlbums(resp.data);
                        break;

                    case "videos":
                        displayVideos(resp.data);
                        break;

                    case "images":
                        displayImages(resp.data);
                        break;
                    default:
                        console.log('no meta type found');
                        break;
                }
            } else {
                console.log('No Data found');
            }
        });
    }

    var isLastPage = false;
    var isLoading = false;

    function showloading(status) {
        if (status == true) {
            isLoading = true;
            $("#loader").show();
        } else {
            isLoading = false;
            $("#loader").hide();
        }
    }

    function ApiPaging(callback) {
        let nextPage = parseInt($('#pagination').attr("data-page")) + 1;
        let datahref = $("#pagination").attr("data-href");
        let reqUrl = datahref ? datahref : location.pathname;
        $.ajax({
            url: reqUrl,
            type: "GET",
            dataType: "json",
            data: {
                page: nextPage
            },
            beforeSend: showloading(true)
        })
            .done(function (resp) {
                showloading(false);
                let totalPage = resp.meta.last_page;
                if (nextPage >= totalPage) {
                    isLastPage = true;
                    $("#loader").remove();
                }
                console.log(nextPage);
                $("#pagination").attr("data-page", nextPage);
                startPagination();
                callback(resp);
            }).fail(function () {
                $("#loader").remove();
                console.log("API Fetch Error" + reqUrl);
            });
    }

    /**
    * if Pagination and 
    *  window and document width same
    */

    function startPagination() {
        if ($(document).height() == $(window).height()) {
            if (!isLoading && !isLastPage) {
                setTimeout(() => {
                    LoadData();
                }, 400);
            }
        }
    }

    if ($("#pagination").length) {

        let scrollHeight = 800;
        if ($(window).height() >= 960) {
            scrollHeight = 500;
        }
        /**
         * on scroll event
         */
        $(window).on("scroll", function () {
            if ($(window).scrollTop() >= $(document).height() - $(window).height() - scrollHeight) {
                if (!isLoading && !isLastPage) {
                    LoadData();
                }
            }
        });
        startPagination();
    }

});